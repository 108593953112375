import { Children } from "react"
import type { PackagePromptStrategy }
  from "@/console/packageAccess/packageAccess.constants"
import type { PackageAccessFragment }
  from "@/console/packageAccess/__types__/packageAccess.fragment"
import { usePackageAccessGuard }
  from "@/console/packageAccess/packageAccess.utils"
import { getPackageStrategy }
  from "@/console/packageAccess/packageAccess.utils"
import { assertCustomPackage }
  from "@/console/packageAccess/packageAccess.utils"

export type ChildrenProps = PackageAccessFragment & {
  strategy?: PackagePromptStrategy
  isCustomPackage?: boolean
}

export type PackageAccessGuardProps = {
  apiName: string,
  apiUsage?: number,
  isAlwaysRendered?: boolean,
  usePackageAccess?: typeof usePackageAccessGuard,
  renderPlaceholder?(packageAccess: PackageAccessFragment): JSX.Element,
  children?(props: ChildrenProps): JSX.Element | JSX.Element[]
}

function PackageAccessGuard(props: PackageAccessGuardProps) {
  const {
    apiName,
    apiUsage = 0,
    isAlwaysRendered = false,
    usePackageAccess = usePackageAccessGuard,
    renderPlaceholder = null,
    children
  } = props

  const { currentPackage, packageAccess } = usePackageAccess(apiName)
  const strategy = getPackageStrategy(currentPackage, packageAccess, apiUsage)
  const isCustomPackage = assertCustomPackage(packageAccess)

  if(isAlwaysRendered === false && (!currentPackage || !strategy)) {
    return renderPlaceholder
      ? renderPlaceholder(packageAccess)
      : null
  }

  if(typeof children === "function") {
    return Children.only(children({
      ...packageAccess,
      isCustomPackage,
      strategy
    }))
  }

  return Children.only(children)
}

export default PackageAccessGuard
