import type { Obj } from "@/common/types"

export type RouteInfo = {
  category: string,
  name: string,
  template?: string,
  search?: string,
  vars?: Obj,
  issueId: number,
  sprintNo: number,
  latestIssueId?: number,
  latestSprintNo?: number
}

export const ROUTES: Record<string, RouteInfo> = {
  MAP_LAYERS: {
    category: "Map",
    name: "LayersPanel",
    search: "layerPanel=1",
    issueId: 0,
    sprintNo: 0,
    latestIssueId: 4237,
    latestSprintNo: 30
  },
  GEO_SEARCH_PANEL: {
    category: "Map",
    name: "GeoSearchPanel",
    template: "/map/@/console/:x/:y/:r",
    issueId: 0,
    sprintNo: 0
  },
  STATISTICS_PANEL: {
    category: "Map",
    name: "StatisticsPanel",
    template: "/map/s",
    issueId: 0,
    sprintNo: 0
  },
  MODEL_NAV_PANEL: {
    category: "3D",
    name: "ModelPanel",
    template: "/map/models",
    issueId: 0,
    sprintNo: 0
  },
  MODEL_SKETCH_PANEL: {
    category: "Model",
    name: "ModelSketcherPanel",
    template: "/map/models/:modelSceneId/sketch",
    issueId: 0,
    sprintNo: 0
  },
  COUNTRY_PANEL: {
    category: "Location",
    name: "CountryPanel",
    template: "/map/countries/:countryCode",
    issueId: 0,
    sprintNo: 0
  },
  COUNTY_PANEL: {
    category: "Location",
    name: "CountyPanel",
    template: "/map/counties/:countyId",
    issueId: 0,
    sprintNo: 0
  },
  MUNICIPALITY_PANEL: {
    category: "Location",
    name: "MunicipalityPanel",
    template: "/map/municipalities/:municipalityId",
    issueId: 0,
    sprintNo: 0
  },
  ZIP_CODE_PANEL: {
    category: "Location",
    name: "ZipCodePanel",
    template: "/map/zip-codes/:zipCodeId",
    issueId: 0,
    sprintNo: 0
  },
  CITY_DISTRICT_PANEL: {
    category: "Location",
    name: "CityDistrictPanel",
    template: "/map/city-districts/:cityDistrictId",
    issueId: 0,
    sprintNo: 0
  },
  SUB_AREA_PANEL: {
    category: "Location",
    name: "SubAreaPanel",
    template: "/map/sub-areas/:subAreaId",
    issueId: 0,
    sprintNo: 0
  },
  BASIC_STATISTICAL_UNIT_PANEL: {
    category: "Location",
    name: "BasicStatisticalUnitPanel",
    template: "/map/basic-statistical-units/:basicStatisticalUnitId",
    issueId: 0,
    sprintNo: 0
  },
  STREET_PANEL: {
    category: "Location",
    name: "StreetPanel",
    template: "/map/streets/:streetId",
    issueId: 0,
    sprintNo: 0
  },
  ADDRESS_PANEL: {
    category: "Location",
    name: "AddressPanel",
    template: "/map/addresses/:addressId",
    issueId: 0,
    sprintNo: 0
  },
  CADASTRE_PANEL_NB: {
    category: "Property",
    name: "CadastrePanel",
    template: "/map/cadastres/:knr/:gnr/:bnr/:fnr/:snr",
    issueId: 0,
    sprintNo: 0
  },
  CADASTRE_PANEL_ID: {
    category: "Property",
    name: "CadastrePanel",
    template: "/map/cadastres/:cadastreId",
    issueId: 0,
    sprintNo: 0
  },
  BUILDING_FLOOR_PANEL: {
    category: "Property",
    name: "BuildingFloorPanel",
    template: "/map/buildings/floors/:buildingFloorId",
    issueId: 0,
    sprintNo: 0
  },
  BUILDING_PANEL_NB: {
    category: "Property",
    name: "BuildingPanel",
    template: "/map/buildings/no/:buildingNumber",
    issueId: 0,
    sprintNo: 0
  },
  BUILDING_PANEL_ID: {
    category: "Property",
    name: "BuildingPanel",
    template: "/map/buildings/:buildingId",
    issueId: 0,
    sprintNo: 0
  },
  OCCUPANCY_PANEL: {
    category: "Property",
    name: "OccupancyPanel",
    template: "/map/occupancies/:occupancyId",
    issueId: 0,
    sprintNo: 0
  },
  COMPANY_PANEL: {
    category: "Industry",
    name: "CompanyPanel",
    template: "/map/companies/:companyId",
    issueId: 0,
    sprintNo: 0
  },
  PARKING_AREA_PANEL: {
    category: "Traffic",
    name: "ParkingAreaPanel",
    template: "/map/parking-areas/:parkingAreaId",
    issueId: 0,
    sprintNo: 0
  },
  SETTING_PANEL: {
    category: "Core",
    name: "SettingPanel",
    template: "/map/user/settings",
    issueId: 0,
    sprintNo: 0
  },
  CURRENT_USER_ACTIVITY_PANEL: {
    category: "CurrentUser",
    name: "CurrentUserActivityPanel",
    template: "/map/user/activities",
    issueId: 0,
    sprintNo: 0
  },
  CURRENT_USER_PROFILE_PANEL: {
    category: "CurrentUser",
    name: "CurrentUserProfilePanel",
    template: "/map/user/profile",
    issueId: 0,
    sprintNo: 0
  },
  CURRENT_USER_API_CLIENT_PANEL: {
    category: "CurrentUser",
    name: "CurrentUserApiClientPanel",
    template: "/map/user/api-client",
    issueId: 7351,
    sprintNo: 29
  },
  REPORT_MENU_PANEL: {
    category: "Report",
    name: "ReportMenuPanel",
    template: "/map/reports",
    issueId: 0,
    sprintNo: 0
  },
  REPORT_VIEW: {
    category: "Report",
    name: "ReportView",
    search: "reportId=",
    issueId: 0,
    sprintNo: 0
  },
  REPORT_VIEW_CUSTOM: {
    category: "Report",
    name: "ReportViewCustom",
    search: "reportSourceId=",
    issueId: 0,
    sprintNo: 0
  },
  REPORTS_CUSTOM: {
    category: "ReportCustom",
    name: "ReportsCustom",
    search: "reportCustomPanel=1",
    issueId: 0,
    sprintNo: 0
  },
  REDEEM_INVITATION_PAGE: {
    category: "Auth",
    name: "RedeemInvitationPage",
    template: "/redeem/:email/:referral",
    issueId: 0,
    sprintNo: 0
  },
  LOGIN_PAGE: {
    category: "Auth",
    name: "LoginPage",
    template: "/login",
    issueId: 0,
    sprintNo: 0
  },
  LOGOUT_PAGE: {
    category: "Auth",
    name: "LogoutPage",
    template: "/logout",
    issueId: 0,
    sprintNo: 0
  },
  PLACE_PANEL: {
    category: "Location",
    name: "PlacePanel",
    template: "/map/places/:placeId",
    issueId: 0,
    sprintNo: 0
  },
  GOVERNMENT_CASE_CASES: {
    category: "GovernmentCase",
    name: "GovernmentCaseCases",
    search: "governmentCasesPanel=1",
    issueId: 0,
    sprintNo: 0
  },
  GOVERNMENT_CASE_CASES_FILTER_CADASTRE: {
    category: "GovernmentCase",
    name: "GovernmentCaseCasesFilterCadastre",
    search: "governmentCaseCasesFilterOption=1",
    issueId: 0,
    sprintNo: 0
  },
  GOVERNMENT_CASE_CASES_FILTER_NEIGHBORS: {
    category: "GovernmentCase",
    name: "GovernmentCaseCasesFilterNeighbors",
    search: "governmentCaseCasesFilterOption=2",
    issueId: 0,
    sprintNo: 0
  },
  GOVERNMENT_CASE_CASES_FILTER_BASICSTATISTICALUNIT: {
    category: "GovernmentCase",
    name: "GovernmentCaseCasesFilterBasicStatisticalUnit",
    search: "governmentCaseCasesFilterOption=3",
    issueId: 0,
    sprintNo: 0
  },
  GOVERNMENT_CASE_CASES_FILTER_ROAD: {
    category: "GovernmentCase",
    name: "GovernmentCaseCasesFilterRoad",
    search: "governmentCaseCasesFilterOption=4",
    issueId: 0,
    sprintNo: 0
  },
  GOVERNMENT_CASE_DOCUMENTS: {
    category: "GovernmentCase",
    name: "GovernmentCaseDocuments",
    search: "governmentCasesPanel=2",
    issueId: 0,
    sprintNo: 0
  },
  GOVERNMENT_CASE_FILES: {
    category: "GovernmentCase",
    name: "GovernmentCaseFiles",
    search: "governmentCasesPanel=3",
    issueId: 0,
    sprintNo: 0
  },
  ANALYZE_PANEL: {
    category: "Map",
    name: "AnalyzePanel",
    search: "analyzeOptionId",
    issueId: 3503,
    sprintNo: 14
  },
  MODEL_SCENE_DETAILS_PANEL: {
    category: "Map",
    name: "ModelSceneDetailsPanel",
    search: "modelScenePanel=details",
    issueId: 4333,
    sprintNo: 23
  },
  TAG_NAV_PANEL: {
    category: "PRM",
    name: "TagNavPanel",
    template: "/map/tags",
    issueId: 0,
    sprintNo: 0
  },
  TAG_OBJECT_PANEL: {
    category: "PRM",
    name: "TagObjectPanel",
    template: "/map/tags/:tagId",
    issueId: 0,
    sprintNo: 0
  },
  ACTIVITY_PANEL: {
    category: "PRM",
    name: "ActivityPanel",
    search: "showActivityPanel=1",
    issueId: 0,
    sprintNo: 0
  },
  PROJECT_ACTIVITY_PANEL: {
    category: "PRM",
    name: "ProjectActivityPanel",
    search: "projectPanel=activities",
    issueId: 6505,
    sprintNo: 24
  },
  PROJECT_OBJECT_PANEL: {
    category: "PRM",
    name: "ProjectObjectPanel",
    search: "projectPanel=objects",
    issueId: 6505,
    sprintNo: 24
  },
  PROJECT_AREA_PANEL: {
    category: "PRM",
    name: "ProjectAreaPanel",
    search: "projectPanel=areas",
    issueId: 6505,
    sprintNo: 24
  },
  PROJECT_MONITOR_PANEL: {
    category: "PRM",
    name: "ProjectMonitorPanel",
    search: "projectPanel=monitor",
    issueId: 6505,
    sprintNo: 24
  }
}
