import {
  type ReactNode,
  useEffect,
  useState,
  createContext,
  useContext,
  useCallback,
  useMemo
} from "react"
import { AnimatePresence } from "framer-motion"
import { randomId } from "@/ui/utils"
import cn from "@/ui/utils/utils.cn"
import type { NotificationProps } from "./Notification"
import Notification from "./Notification"

type BaseProps = Omit<NotificationProps, "children" | "onDismiss" | "id">
export type NotificationOptions = BaseProps & {
  id?: string,
  content?: ReactNode,
  onDismiss?: NotificationProps["onDismiss"]
}

type NotificationContextProps = {
  addNotification(options: NotificationOptions): () => void,
  clearNotification(id: string): void,
  clearAllNotifications(): void
}

const NotificationContext = createContext<NotificationContextProps>({
  addNotification: null,
  clearNotification: null,
  clearAllNotifications: null
})

type Placement = "top-right"
  | "top-left"
  | "top-center"
  | "bottom-right"
  | "bottom-left"
  | "bottom-center"
  | "center-center"

export type NotificationProviderProps = {
  children?: ReactNode,
  placement?: Placement
}

declare global {
  interface Window {
    addNotification: (options: NotificationOptions) => () => void
  }
}

const NotificationProvider = (props: NotificationProviderProps) => {
  const {
    placement = "top-right",
    children
  } = props

  const [ notifications, setNotifications ] = useState<
    (NotificationOptions)[]
  >([])

  const clearNotification = useCallback((id: string) => {
    setNotifications((ns) => ns.filter((n) => n.id !== id))
  }, [])

  const clearAllNotifications = useCallback(() => {
    setNotifications([])
  }, [])

  const addNotification = useCallback((options: NotificationOptions) => {
    const id = options.id || randomId()
    const addedAt = new Date()

    const clear = () => {
      if(!options.minTimeout) {
        clearNotification(id)
        return
      }

      const now = new Date()
      const deltaNow = +now - +addedAt
      const deltaTimeout = options.minTimeout - deltaNow

      if(deltaTimeout > 0) {
        const t = setTimeout(() => {
          clearNotification(id)
          clearTimeout(t)
        }, deltaTimeout)
      } else {
        clearNotification(id)
      }
    }

    const notification = {
      ...options,
      id
    }

    setNotifications((notifications) => {
      const newNotifications = [
        notification,
        ...notifications
      ]

      const isExisting = notifications
        .some(({ id }) => id === notification.id)

      if(isExisting) {
        return notifications
      }

      return newNotifications
    })

    return clear
  }, [
    clearNotification
  ])

  useEffect(() => {
    window.addNotification = addNotification
  }, [
    addNotification
  ])

  const value = useMemo(() => ({
    addNotification,
    clearNotification,
    clearAllNotifications
  }), [
    addNotification,
    clearNotification,
    clearAllNotifications
  ])

  return (
    <NotificationContext.Provider value={value}>
      <div className={cn("notification-container", placement)}>
        <AnimatePresence initial={false}>
          {notifications.map(({
            id,
            content,
            onDismiss,
            ...props
          }) => (
            <Notification
              key={id}
              onDismiss={(e) => {
                if(onDismiss) onDismiss(e)
                clearNotification(id)
              }}
              {...props}
            >
              {content}
            </Notification>
          ))}
        </AnimatePresence>
      </div>

      {children}
    </NotificationContext.Provider>
  )
}

export function useNotifications() {
  return useContext(NotificationContext)
}

export default NotificationProvider
