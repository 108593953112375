import { track } from "@/console/analytics"

export function trackAuthLogin() {
  track("Auth_Login", {
    module: "Auth",
    action: "Login",
    issueId: 0,
    sprintNo: 0
  })
}

export function trackAuthLogout() {
  track("Auth_Logout", {
    module: "Auth",
    action: "Logout",
    issueId: 0,
    sprintNo: 0
  })
}
