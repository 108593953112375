import type { ComponentProps } from "react"
import cn from "@/ui/utils/utils.cn"
import createRipple from "@/ui/utils/utils.ripple"
import Icon, { type IconProps } from "@/ui/Icon"
import Link, { type LinkProps } from "."

function ExternalLinkIcon(props: ComponentProps<"svg">) {
  return (
    <svg
      fill="#fff"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      width="48px"
      height="48px"
      {...props}
    >
      <path
        d="M 40.960938 4.9804688 A 2.0002 2.0002 0 0 0 40.740234 5 L 28 5 A 2.0002 2.0002 0 1 0 28 9 L 36.171875 9 L 22.585938 22.585938 A 2.0002 2.0002 0 1 0 25.414062 25.414062 L 39 11.828125 L 39 20 A 2.0002 2.0002 0 1 0 43 20 L 43 7.2460938 A 2.0002 2.0002 0 0 0 40.960938 4.9804688 z M 12.5 8 C 8.3826878 8 5 11.382688 5 15.5 L 5 35.5 C 5 39.617312 8.3826878 43 12.5 43 L 32.5 43 C 36.617312 43 40 39.617312 40 35.5 L 40 26 A 2.0002 2.0002 0 1 0 36 26 L 36 35.5 C 36 37.446688 34.446688 39 32.5 39 L 12.5 39 C 10.553312 39 9 37.446688 9 35.5 L 9 15.5 C 9 13.553312 10.553312 12 12.5 12 L 22 12 A 2.0002 2.0002 0 1 0 22 8 L 12.5 8 z"
      />
    </svg>
  )
}

export type LinkIconProps = LinkProps & {
  iconSrc?: IconProps["src"],
  iconSize?: IconProps["size"],
  iconClassName?: IconProps["className"],
  iconStyle?: IconProps["style"],
  isRippleEnabled?: boolean
}

function LinkIcon(props: LinkIconProps) {
  const {
    className,
    iconSrc,
    iconSize = 12,
    iconClassName,
    iconStyle,
    children,
    target,
    isRippleEnabled = true,
    onMouseDown,
    ...linkProps
  } = props

  return (
    <Link
      className={cn(className, "inline-flex", {
        "relative overflow-hidden": isRippleEnabled
      })}
      target={target}
      onMouseDown={(e) => {
        if(isRippleEnabled) createRipple(e)
        if(onMouseDown) onMouseDown(e)
      }}
      {...linkProps}
    >
      {children}

      {(iconSrc || !target || target === "_self")
        ? <Icon
            src={iconSrc}
            size={iconSize}
            style={iconStyle}
            className={cn(iconClassName, "inline", {
              "ml-1.5": !!children
            })}
          />
        : <ExternalLinkIcon
            height={iconSize}
            width={iconSize}
            className={cn(iconClassName, "inline", {
              "ml-1.5": !!children
            })}
          />
      }
    </Link>
  )
}

export default LinkIcon
