import type { ComponentProps, ReactNode } from "react"
import cn from "@/ui/utils/utils.cn"
import createRipple from "@/ui/utils/utils.ripple"
import Icon from "@/ui/Icon"
import type { IconProps } from "@/ui/Icon"

export type ButtonProps = ComponentProps<"button"> & {
  iconContent?: ReactNode,
  iconSrc?: IconProps["src"],
  iconClassName?: IconProps["className"],
  iconSize?: IconProps["size"],
  iconStyle?: IconProps["style"],
  loading?: IconProps["loading"],
  small?: boolean,
  tiny?: boolean,
  micro?: boolean,
  intent?: "success" | "warning" | "primary" | "danger" | "secondary",
  stacked?: boolean,
  ripple?: boolean,
  visible?: boolean,
  isText?: boolean
}

function Button(props: ButtonProps) {
  const {
    iconContent,
    iconSrc,
    iconClassName,
    iconStyle,
    iconSize,
    children,
    className,
    loading,
    small,
    tiny,
    micro,
    disabled,
    stacked,
    intent,
    ripple = true,
    visible = true,
    isText,
    type = "button",
    onMouseDown,
    ...buttonProps
  } = props

  return (
    <button
      type={type}
      disabled={disabled || loading}
      className={cn("btn", className, intent, {
        "small": small,
        "tiny": tiny,
        "micro": micro,
        "invisible": !visible,
        "stacked": stacked,
        "no-children": !children,
        "is-loading": loading,
        "is-text": isText
      })}
      onMouseDown={(e) => {
        if(ripple) createRipple(e)
        if(onMouseDown) onMouseDown(e)
      }}
      {...buttonProps}
    >
      {(iconContent || iconSrc || loading) && (
        (iconContent && !loading) ? iconContent : (
          <Icon
            aria-hidden={true}
            className={cn("icn", iconClassName)}
            style={iconStyle}
            loading={loading}
            src={iconSrc}
            size={iconSize || (small
              ? stacked || !children
                ? 14
                : 12
              : stacked
                ? 18
                : 15
              )
            }
          />
        )
      )}

      {children}
    </button>
  )
}

export default Button
