import type { ComponentProps } from "react"
import cn from "@/ui/utils/utils.cn"

type AuthErrorProps = ComponentProps<"div"> & {
  error?: Error
}

function AuthError(props: AuthErrorProps) {
  const { error, className, ...divProps } = props
  if(!error) return null
  return (
    <div
      className={cn("max-w-lg text-red-600 text-center", className)}
      role="alert"
      aria-label="Feil oppstod"
      {...divProps}
    >
      <div>
        Feil med autentiseringen, vennligst logg inn på nytt
      </div>

      <div className="text-sm">
        Feilmelding: {error.message}
      </div>
    </div>
  )
}

export default AuthError
