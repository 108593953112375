import { forwardRef } from "react"
import type { ComponentProps } from "react"
import type { DisplaySize } from "@/ui/constants"
import cn from "@/ui/utils/utils.cn"
import Button from "@/ui/Button"
import type { ButtonProps } from "@/ui/Button"
import "./common.css"
import "./input.css"

export function InputResetButton(props: ButtonProps) {
  const {
    className,
    iconSrc = "cross-small",
    small = true,
    ...buttonProps
  } = props

  return (
    <Button
      className={cn("input-reset-btn mr-2", className)}
      small={small}
      iconSrc={iconSrc}
      {...buttonProps}
    />
  )
}

export type InputProps = Omit<ComponentProps<"input">, "ref"> & {
  displaySize?: DisplaySize
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  function BaseInput(props, ref) {
    const {
      name,
      className,
      displaySize,
      ...inputProps
    } = props

    return (
      <input
        name={name}
        ref={ref}
        className={cn("input", className, displaySize)}
        {...inputProps}
      />
    )
  }
)

export default Object.assign(Input, {
  ResetButton: InputResetButton
})
