import type { ComponentProps } from "react"
import cn from "@/ui/utils/utils.cn"

export type FrameFooterProps = ComponentProps<"footer">

function FrameFooter(props: FrameFooterProps) {
  const { className, ...footerProps } = props
  return (
    <footer
      className={cn("frame-footer", className)}
      {...footerProps}
    />
  )
}

export default FrameFooter
