import { useMemo } from "react"
import { logWarning } from "@/ui/utils/utils.log"
import { useAppContext } from "@/console/app/context/AppContextProvider"
import { PACKAGE_PROMPT_STRATEGY, CUSTOM_PACKAGE_CODES }
  from "@/console/packageAccess/packageAccess.constants"
import type { PackageFragment }
  from "@/console/package/__types__/package.fragment"
import type { PackageAccessFragment }
  from "@/console/packageAccess/__types__/packageAccess.fragment"

export function getPackageStrategy(
  currentPackage: PackageFragment,
  packageAccess: PackageAccessFragment,
  currentApiUsage = 0
) {
  if(!currentPackage || !packageAccess) return null

  const hasAccess = packageAccess.HasAccess
  const hasLimitations = Number.isInteger(packageAccess.Limit)
  const isApiLimitReached = hasLimitations
    ? currentApiUsage >= packageAccess.Limit
    : null

  if(!hasAccess || isApiLimitReached) {
    return PACKAGE_PROMPT_STRATEGY.disableAndPromote
  }
  if(hasAccess && !hasLimitations) {
    return PACKAGE_PROMPT_STRATEGY.none
  }
  if(hasAccess && hasLimitations) {
    return PACKAGE_PROMPT_STRATEGY.enableAndPromote
  }

  return null
}

export function usePackageAccessGuard(apiName: string) {
  const { currentUser } = useAppContext()

  const packageAccess = useMemo(() => {
    if(!apiName || !currentUser?.PackageAccesses) return null

    const pa = currentUser.PackageAccesses
      .find((pa) => pa.ApiName === apiName)

    if(!pa) {
      logWarning("No PackageAccess found for the given ApiName", {
        contexts: {
          input: { apiName }
        }
      })
      return null
    }

    return pa
  }, [
    currentUser,
    apiName
  ])

  return {
    nextPackageCode: packageAccess?.NextPackageCode,
    currentPackage: currentUser?.Package,
    packageAccess
  }
}

export function assertCustomPackage(packageAccess: PackageAccessFragment) {
  return packageAccess?.SubscriptionTypeCode === CUSTOM_PACKAGE_CODES.custom
}
