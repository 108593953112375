import { ASSETS_BASE_URL } from "@/ui/constants"

export function getAssetUrl<T extends string = string>(path: string) {
  return `${ASSETS_BASE_URL}/${path}` as T
}

export function mapAssetNamesToUrls(
  baseUrl: string,
  names: string[],
  ext = "svg"
) {
  const ns: Record<string, string> = {}
  for(const name of names) {
    ns[name] = getAssetUrl(`${baseUrl}/${name}.${ext}`)
  }
  return ns
}
