import cn from "@/ui/utils/utils.cn"
import Package from "@/ui/Package"
import Sidebar, { type SidebarItemProps } from "@/ui/Sidebar"
import PackageAccessGuard from "@/console/packageAccess/PackageAccessGuard"
import { PACKAGE_ACCESS_API_NAMES }
  from "@/console/packageAccess/packageAccess.constants"

const API_NAME = PACKAGE_ACCESS_API_NAMES["Product_3D_designer"]

export type AppSidebarItemModelsProps = SidebarItemProps

function AppSidebarItemModels(props: AppSidebarItemModelsProps) {
  const { className, isActive, ...sidebarItemProps } = props

  return (
    <PackageAccessGuard
      isAlwaysRendered
      apiName={API_NAME}
    >
      {(guard) => (
        <Sidebar.Item
          isActive={isActive}
          isDisabled={!guard.HasAccess}
          contentClassName={cn("flex items-center", className, {
            "p-0 border-0 bg-transparent shadow-none": !!guard.NextPackage
          })}
          popoverIsAlwaysEnabled={!isActive && !!guard.NextPackage}
          popoverClassName={guard.NextPackage ? "pointer-events-auto" : undefined}
          popoverContent={guard.NextPackage
            ? <Package.Prompt
                packageCode={guard.NextPackageCode}
                packageIcon={guard.NextPackage.Icon}
                packageName={guard.NextPackage.Name}
              >
                <Package.PromptText
                  text="3D-designer er tilgjengelig i våre mer omfattende pakker."
                />
              </Package.Prompt>
            : null
          }
          {...sidebarItemProps}
        >
          <div className="mr-1">
            3D-designer
          </div>

          {guard.NextPackage
            ? <img
                className="ml-auto"
                src={guard.NextPackage.Icon}
                alt={guard.NextPackage.Name}
              />
            : null
          }
        </Sidebar.Item>
      )}
    </PackageAccessGuard>
  )
}

export default AppSidebarItemModels
