import { useSearchParamOpener } from "@/console/components/component.utils"

export function useProjectOpener() {
  const opener = useSearchParamOpener("projectId")
  opener.isActive = opener.isActive && opener.activeValue !== "0"
  return opener
}

export function useProjectPanelOpener() {
  return useSearchParamOpener("projectPanel")
}
