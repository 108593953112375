/* eslint-disable react/jsx-max-props-per-line */
import { lazy } from "react"
import { Routes as ReactRoutes, Route } from "react-router-dom"
import { ROUTES as R } from "@/console/router/router.constants"
import { lazyRetry } from "@/console/router/router.utils"
import AuthGuard from "@/console/auth/AuthGuard"
import AppOutlet from "@/console/app/AppOutlet"
import Redirect from "@/console/router/Redirect"

const MapPage = lazy(() => lazyRetry(() => import("@/console/map/MapPage")))
const GeoSearchPanel = lazy(() => lazyRetry(() => import("@/console/geoSearch/GeoSearchPanel")))
const StatisticsPanel = lazy(() => lazyRetry(() => import("@/console/statistics/StatisticsPanel")))
const CompanyPanel = lazy(() => lazyRetry(() => import("@/console/company/panel/CompanyPanel")))
const CadastrePanel = lazy(() => lazyRetry(() => import("@/console/cadastre/panel/CadastrePanel")))
const ZipCodePanel = lazy(() => lazyRetry(() => import("@/console/zipCode/ZipCodePanel")))
const AddressPanel = lazy(() => lazyRetry(() => import("@/console/address/panel/AddressPanel")))
const StreetPanel = lazy(() => lazyRetry(() => import("@/console/street/StreetPanel")))
const CountyPanel = lazy(() => lazyRetry(() => import("@/console/county/CountyPanel")))
const BuildingPanel = lazy(() => lazyRetry(() => import("@/console/building/panel/BuildingPanel")))
const OccupancyPanel = lazy(() => lazyRetry(() => import("@/console/occupancy/panel/OccupancyPanel")))
const BuildingFloorPanel = lazy(() => lazyRetry(() => import("@/console/building/floor/BuildingFloorPanel")))
const CountryPanel = lazy(() => lazyRetry(() => import("@/console/country/CountryPanel")))
const BasicStatisticalUnitPanel = lazy(() => lazyRetry(() => import("@/console/basicStatisticalUnit/panel/BasicStatisticalUnitPanel")))
const SubAreaPanel = lazy(() => lazyRetry(() => import("@/console/subArea/SubAreaPanel")))
const CityDistrictPanel = lazy(() => lazyRetry(() => import("@/console/cityDistrict/CityDistrictPanel")))
const MunicipalityPanel = lazy(() => lazyRetry(() => import("@/console/municipality/panel/MunicipalityPanel")))
const ParkingAreaPanel = lazy(() => lazyRetry(() => import("@/console/parkingArea/ParkingAreaPanel")))
const TagNavPanel = lazy(() => lazyRetry(() => import("@/console/tag/nav/TagNavPanel")))
const TagObjectPanel = lazy(() => lazyRetry(() => import("@/console/tag/object/TagObjectPanel")))
const AuthRedeemInvitationPage = lazy(() => lazyRetry(() => import("@/console/auth/AuthRedeemInvitationPage")))
const AuthLoginPage = lazy(() => lazyRetry(() => import("@/console/auth/AuthLoginPage")))
const AuthLogoutPage = lazy(() => lazyRetry(() => import("@/console/auth/AuthLogoutPage")))
const ModelSceneNavPanel = lazy(() => lazyRetry(() => import("@/console/model/scene/nav/ModelSceneNavPanel")))
const ModelSketcherPanel = lazy(() => lazyRetry(() => import("@/console/model/sketcher/ModelSketcherPanel")))
const SettingPanel = lazy(() => lazyRetry(() => import("@/console/setting/SettingPanel")))
const CurrentUserActivityPanel = lazy(() => lazyRetry(() => import("@/console/currentUser/activity/CurrentUserActivityPanel")))
const CurrentUserProfilePanel = lazy(() => lazyRetry(() => import("@/console/currentUser/profile/CurrentUserProfilePanel")))
const CurrentUserApiClientPanel = lazy(() => lazyRetry(() => import("@/console/currentUser/apiClient/CurrentUserApiClientPanel")))
const ReportMenuPanel = lazy(() => lazyRetry(() => import("@/console/report/menu/ReportMenuPanel")))
const PlacePanel = lazy(() => lazyRetry(() => import("@/console/place/panel/PlacePanel")))

function Routes() {
  return (
    <ReactRoutes>
      <Route path="/" element={<AuthGuard><AppOutlet /></AuthGuard>}>
        <Route path="/map" element={<MapPage />}>
          <Route path={R.GEO_SEARCH_PANEL.template} element={<GeoSearchPanel />} />
          <Route path={R.STATISTICS_PANEL.template} element={<StatisticsPanel />} />
          <Route path={R.MODEL_NAV_PANEL.template} element={<ModelSceneNavPanel />} />
          <Route path={R.MODEL_SKETCH_PANEL.template} element={<ModelSketcherPanel />} />
          <Route path={R.COUNTRY_PANEL.template} element={<CountryPanel />} />
          <Route path={R.COUNTY_PANEL.template} element={<CountyPanel />} />
          <Route path={R.MUNICIPALITY_PANEL.template} element={<MunicipalityPanel />} />
          <Route path={R.ZIP_CODE_PANEL.template} element={<ZipCodePanel />} />
          <Route path={R.CITY_DISTRICT_PANEL.template} element={<CityDistrictPanel />} />
          <Route path={R.SUB_AREA_PANEL.template} element={<SubAreaPanel />} />
          <Route path={R.BASIC_STATISTICAL_UNIT_PANEL.template} element={<BasicStatisticalUnitPanel />} />
          <Route path={R.STREET_PANEL.template} element={<StreetPanel />} />
          <Route path={R.ADDRESS_PANEL.template} element={<AddressPanel />} />
          <Route path={R.CADASTRE_PANEL_NB.template} element={<CadastrePanel />} />
          <Route path={R.CADASTRE_PANEL_ID.template} element={<CadastrePanel />} />
          <Route path={R.BUILDING_FLOOR_PANEL.template} element={<BuildingFloorPanel />} />
          <Route path={R.BUILDING_PANEL_NB.template} element={<BuildingPanel />} />
          <Route path={R.BUILDING_PANEL_ID.template} element={<BuildingPanel />} />
          <Route path={R.OCCUPANCY_PANEL.template} element={<OccupancyPanel />} />
          <Route path={R.COMPANY_PANEL.template} element={<CompanyPanel />} />
          <Route path={R.PARKING_AREA_PANEL.template} element={<ParkingAreaPanel />} />
          <Route path={R.SETTING_PANEL.template} element={<SettingPanel />} />
          <Route path={R.CURRENT_USER_ACTIVITY_PANEL.template} element={<CurrentUserActivityPanel />} />
          <Route path={R.CURRENT_USER_PROFILE_PANEL.template} element={<CurrentUserProfilePanel />} />
          <Route path={R.CURRENT_USER_API_CLIENT_PANEL.template} element={<CurrentUserApiClientPanel />} />
          <Route path={R.REPORT_MENU_PANEL.template} element={<ReportMenuPanel />} />
          <Route path={R.PLACE_PANEL.template} element={<PlacePanel />} />
          <Route path={R.TAG_NAV_PANEL.template} element={<TagNavPanel />} />
          <Route path={R.TAG_OBJECT_PANEL.template} element={<TagObjectPanel />} />
        </Route>
        <Route path="/" element={<Redirect />} />
      </Route>
      <Route path={R.REDEEM_INVITATION_PAGE.template} element={<AuthRedeemInvitationPage />} />
      <Route path={R.LOGIN_PAGE.template} element={<AuthLoginPage />} />
      <Route path={R.LOGOUT_PAGE.template} element={<AuthLogoutPage />} />
      <Route path="*" element={<Redirect />} />
    </ReactRoutes>
  )
}

export default Routes
