import type { ComponentProps } from "react"
import cn from "@/ui/utils/utils.cn"
import { getInitialsFromString } from "@/ui/utils/utils.formatting"

type BaseProps = Omit<ComponentProps<"div">, "children">
export type AvatarProps = BaseProps & {
  displaySize?: "xs" | "sm" | "md" | "lg",
  length?: number,
  isLoading?: boolean,
  children: string
}

function Avatar(props: AvatarProps) {
  const {
    children,
    displaySize = "md",
    length,
    isLoading,
    className,
    ...divProps
  } = props

  if(isLoading) {
    return <div className={cn("avatar-loader bg-loading", className, displaySize)} />
  }

  const len = length
    ? length
    : displaySize === "xs" || displaySize === "sm"
      ? 2
      : 1

  return (
    <div
      className={cn("avatar", displaySize, className)}
      {...divProps}
    >
      {getInitialsFromString(children, len)}
    </div>
  )
}

export default Avatar
