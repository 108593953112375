import type { ComponentProps } from "react"
import cn from "@/ui/utils/utils.cn"

export type LineLoaderProps = ComponentProps<"div"> & {
  loading?: boolean,
  height?: number | string,
  width?: number | string,
  loadingClassName?: string
}

function LineLoader(props: LineLoaderProps) {
  const {
    loading,
    height = 16,
    width = "100%",
    style,
    className,
    loadingClassName,
    children,
    ...divProps
  } = props

  if(loading) {
    return (
      <div
        className={cn("bg-loading", className, loadingClassName)}
        style={{
          ...style,
          height,
          width
        }}
        {...divProps}
      />
    )
  }

  return (
    <div
      className={className}
      style={style}
      {...divProps}
    >
      {children}
    </div>
  )
}

export default LineLoader
