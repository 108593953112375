import type { Key, Obj } from "@/common/types"

type ClampOptions = {
  min?: number,
  max?: number
}

/**
 * Ensures a number stays within a given min/max range, or simply
 * the min or the max when both are not specified
 */
export function clamp(value: number, options: ClampOptions) {
  const { min, max } = options
  if(Number.isInteger(min) && value < min) value = min
  if(Number.isInteger(max) && value > max) value = max
  return value
}

type RangeOptions = {
  min?: number,
  max?: number
}

/**
 * Create an array with empty elements up to a given count.
 * Specify options to clamp length
 */
export function range(count: number, options?: RangeOptions) {
  if(options) count = clamp(count, options)
  return Array.from<never>({ length: count })
}

/**
 * Turns every value of a primitive object into a string
 */
export function stringifyObjectValues(obj: Obj) {
  const out: Record<string, string> = {}
  for(const key in obj) {
    const val = obj[key]
    if(val !== undefined && val !== null) {
      out[key] = String(val)
    }
  }
  return out
}

export function calcPercentage(
  n: number | string,
  d: number | string
) {
  if(!n || !d) return 0
  return Number(n) / Number(d) * 100
}

export function calcMedian(values: number[]) {
  values.sort((a, b) => a - b)
  const half = Math.floor(values.length / 2)
  return values.length % 2
    ? values[half]
    : (values[half - 1] + values[half]) / 2
}

/**
 * Inverts a number within a specified range
 *
 * @param number - The number to be inverted
 * @param min - The minimum value of the range
 * @param max - The maximum value of the range
 * @returns The inverted number within the specified range
 */
export function invertNumberInRange(number: number, min: number, max: number) {
  return Number.isNaN(number)
    ? null
    : max - (number - min)
}

export function hasProp(i: Obj, k: string) {
  return Object.prototype.hasOwnProperty.call(i, k) && !!i[k]
}

export function assertObj(obj: unknown): obj is object {
  return obj && typeof obj === "object"
}

/**
 * Safely parses a JSON string into an object.
 *
 * If the value is a string, it attempts to parse it as JSON.
 * If the value is an object with keys, it returns the object.
 * In all other cases or if parsing fails, it returns `null`.
 *
 * @template T - The expected return type. Default is an object.
 * @param {unknown} val - The value to be parsed.
 * @returns {T} - Parsed JSON value, the original object if it's
 *                non-empty, or `null`.
 */
export function safeJSONParse<T = Obj>(val: unknown): T {
  try {
    if(typeof val === "string") {
      return JSON.parse(val)
    }
    if(assertObj(val)) {
      const z = Object.keys(val)
      return z.length > 0
        ? val as T
        : null
    }
    return null
  } catch{
    return null
  }
}

/**
 * @note the difference between undefined and null
 * undefined will skip values, null will "reset" them
 */
export function shallowMergeDiscard<T extends Obj>(
  value: T,
  defaults?: T,
  filterByDefaultKeys = true
) {
  const out: T = filterByDefaultKeys
    ? { ...defaults }
    : { ...value }
  for(const k in out) {
    if(value[k] !== undefined) out[k] = value[k]
  }
  return out
}

/**
 * Serializes an object into a JSON string after performing a shallow merge
 * with the provided default values.
 *
 * @template T - The type of the object being serialized.
 * @param {T} value - The object to serialize.
 * @param {T} defaults - The default values to merge before serialization.
 * @param Boolean filterByDefaultKeys - Keys in value that isn't in default
 *                                      will be discarded
 */
export function serializeJSON<T extends Obj>(
  value: T,
  defaults: T,
  filterByDefaultKeys = true
) {
  return value
    ? JSON.stringify(shallowMergeDiscard(value, defaults, filterByDefaultKeys))
    : ""
}

/**
 * Deserializes a JSON string into an object. If deserialization fails or
 * the input string is falsy, it will return the provided default values.
 *
 * @template T - The type of the object being deserialized.
 * @param {string} [value] - The JSON string to deserialize.
 * @param {T} [defaults] - The default values to return in case of
 *                         deserialization failure.
 */
export function deSerializeJSON<T extends Obj>(value?: string, defaults?: T) {
  if(!value) return defaults
  const parsed = safeJSONParse<T>(value)
  if(!parsed) return defaults
  return shallowMergeDiscard(parsed, defaults)
}

/**
 * Prunes the object of null and undefined values, and stringifies
 * all values
 */
export function normalizeObject<T extends Obj = Obj>(input: T) {
  const normal = {} as Record<keyof T, string>
  for(const key in input) {
    const val = input[key]
    if(val !== null && val !== undefined) {
      normal[key] = typeof val === "object"
        ? JSON.stringify(val)
        : val as string
    }
  }
  return normal
}

/**
 * Domain is returned with TLD
 *
 * @example
 * const d = extractEmailDomain("pha@bla.placepoint.co.uk")
 * console.log(d) // bla.placepoint.co.uk
 */
export function extractEmailDomain(email: string) {
  return email.split("@")[1]
}

export function sortByKeyAndNumber<T extends Obj>(key: Key<T>) {
  return (a: T, b: T) => {
    if(!Number.isInteger(a[key])) return 1
    if(!Number.isInteger(b[key])) return -1
    if(!Number.isInteger(a[key]) && !Number.isInteger(b[key])) return 0
    return (a[key] as number) - (b[key] as number)
  }
}
