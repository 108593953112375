import { track } from "@/console/analytics"

export function trackCurrentUserMenuShown() {
  track("CurrentUser_Menu_Shown", {
    module: "CurrentUser",
    source: "Menu",
    action: "Shown",
    issueId: 0,
    sprintNo: 0
  })
}

export function trackCurrentUserMenuActiveTenantChanged() {
  track("CurrentUser_Menu_ActiveTenantChanged", {
    module: "CurrentUser",
    source: "Menu",
    action: "ActiveTenantChanged",
    issueId: 0,
    sprintNo: 0
  })
}

export function trackCurrentUserActivityExported() {
  track("CurrentUser_Activity_Exported", {
    module: "CurrentUser",
    source: "Activity",
    action: "Exported",
    format: "csv",
    issueId: 0,
    sprintNo: 0
  })
}

export function trackCurrentUserLayerPreferencesRemembered(
  isEnabled: boolean
) {
  track("CurrentUser_LayerPreferences_Remembered", {
    module: "CurrentUser",
    source: "LayerPreferences",
    action: "Remembered",
    isEnabled,
    issueId: 0,
    sprintNo: 0
  })
}

export function trackCurrentUserProfileUpdated() {
  track("CurrentUser_Profile_Updated", {
    module: "CurrentUser",
    source: "Profile",
    action: "Updated",
    issueId: 0,
    sprintNo: 0
  })
}
