import { InteractionStatus } from "@azure/msal-browser"
import { useMsal } from "@azure/msal-react"
import ActivityIndicator from "@/ui/ActivityIndicator"

const baseMsg = "Venter på autentisering"

function getLoadingMessage(msg: InteractionStatus) {
  if(!msg) return `${baseMsg}..`
  return `${baseMsg} (${msg})..`
}

type AuthLoaderProps = {
  children: any
}

function AuthLoader(props: AuthLoaderProps) {
  const { inProgress } = useMsal()

  if(!inProgress || inProgress !== InteractionStatus.None) {
    return (
      <ActivityIndicator>
        {getLoadingMessage(inProgress)}
      </ActivityIndicator>
    )
  }

  return props.children
}

export default AuthLoader
