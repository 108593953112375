import Badge from "@/ui/Badge"
import Sidebar, { type SidebarItemProps } from "@/ui/Sidebar"
import { useSearchParams } from "@/console/router/router.utils"
import {
  SHOW_GLOBAL_SEARCH_PARAM_KEY,
  SHOW_GLOBAL_SEARCH_PARAM_VALUES
} from "@/console/globalSearch/globalSearch.constant"

const PARAM = `${SHOW_GLOBAL_SEARCH_PARAM_KEY}=${SHOW_GLOBAL_SEARCH_PARAM_VALUES.OPEN}`

export type GlobalSearchSidebarItemProps = Omit<
  SidebarItemProps,
  "isActive" | "popoverContent"
>

function GlobalSearchSidebarItem(props: GlobalSearchSidebarItemProps) {
  const { onClick, ...sidebarItemProps } = props
  const [ getSearchParmas, setSearchParams ] = useSearchParams()

  return (
    <Sidebar.Item
      isActive={window.location.search.includes(PARAM)}
      onClick={(e) => {
        setSearchParams({
          showGlobalSearch: getSearchParmas
            .get(SHOW_GLOBAL_SEARCH_PARAM_KEY) === SHOW_GLOBAL_SEARCH_PARAM_VALUES.OPEN
              ? SHOW_GLOBAL_SEARCH_PARAM_VALUES.CLOSE
              : SHOW_GLOBAL_SEARCH_PARAM_VALUES.OPEN
        })
        if(onClick) onClick(e)
      }}
      popoverContent={
        <div className="flex items-center">
          Søk
          <Badge className="ml-2 -mr-1">
            ctrl+g
          </Badge>
        </div>
      }
      {...sidebarItemProps}
    >
      Søk
      <Badge className="ml-auto">
        ctrl+g
      </Badge>
    </Sidebar.Item>
  )
}

export default GlobalSearchSidebarItem
