import type { Primitive } from "@/common/types"
import { formatDate } from "@/ui/utils/utils.date"

export function getCurrency(
  amount?: number | bigint,
  locales: string | string[] = "nb-NO",
  options?: Intl.NumberFormatOptions
) {
  if(!amount) return "N/A"
  return "kr " + new Intl.NumberFormat(locales, options)
    .format(amount)
}

export function thousandSeparator(n?: number | string, fallbackValue = "0") {
  if(!n) return fallbackValue
  return String(n).replaceAll(/\B(?=(\d{3})+(?!\d))/g, " ")
}

export function formatNumberWithSuffixAndDecimals(
  decimals = 0,
  locales: string | string[] = "nb-NO"
) {
  const formatter = new Intl.NumberFormat(locales, {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals
  })

  return (n: number | string) => {
    if(!n) return "0"
    let num = Number(n)
    let suffix = ""

    if(num >= 1_000_000) {
      num /= 1_000_000
      suffix = "M"
    } else if(num >= 1_000) {
      num /= 1_000
      suffix = "K"
    }

    const val = formatter.format(num)

    // Remove unnecessary decimal places
    if(val.includes(".") && Number.parseFloat(val.split(".")[1]) === 0) {
      return val.split(".")[0] + suffix
    }

    return val + suffix
  }
}

export function getInitialsFromString(str: string, len = 2) {
  if(!str) return "?"
  const regex = str.replaceAll(/[^\d a-zåæø]/gi, "")
  const split = regex.split(" ")
  if(split.length === 1 || len === 1) return regex.charAt(0)
  const n1 = split[0].charAt(0)
  const n2 = split[1].charAt(0)
  return `${n1}${n2}`
}

export function calcPercentage(
  n: number | string,
  d: number | string
) {
  if(!n || !d) return 0
  return Number(n) / Number(d) * 100
}

export function formatPercentage(
  val: number,
  decimals = 0,
  unit = true
) {
  const str = val.toFixed(decimals).replace(".", ",")
  if(!unit) return str
  return str + "%"
  // @todo: replace the above with Intl, but fix wonkiness first..
  // return new Intl.NumberFormat("nb-NO", {
  //   style: "percent",
  //   maximumFractionDigits: decimals
  // }).format(val)
}

export function getPercentage(
  n: number | string,
  d: number | string,
  decimals = 0,
  unit = true
) {
  if(Number.isNaN(n) || Number.isNaN(d)) return ""
  return formatPercentage(calcPercentage(n, d), decimals, unit)
}

export function mark(value: string, regex: RegExp) {
  if(!value) return value
  return value.replace(regex, "<mark>$&</mark>")
}

export type FormatValue = "string"
  | "number"
  | "nok"
  | "date"
  | "boolean"

export function formatPrimitiveValue(value: Primitive, type: FormatValue) {
  if(value === undefined || value === null) return null
  if(!type || type === "string") return value
  if(type === "boolean") return value ? "Ja" : "Nei"
  if(type === "date") return formatDate(value as string)
  if(type === "number" || type === "nok") {
    value = thousandSeparator(value as number)
  }
  if(type === "number") return value
  if(type === "nok") return `kr ${value}`
}
