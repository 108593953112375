import { useMemo } from "react"
import IdentityManager from "@arcgis/core/identity/IdentityManager"
import { useNotifications } from "@/ui/Notification"
import AppContextErrorNotification
  from "@/console/app/context/AppContextErrorNotification"
import useAppContextQuery from "@/console/app/context/app.context.query"
import { ONE_HOUR_IN_MS } from "@/console/constants"
import { identify } from "@/console/analytics"
import { setUser } from "@/console/sentry"
import config from "@/console/config"
import { groupFeature2WithValueTypeByApiName }
  from "@/console/feature/feature.utils"
import AppContextProvider, {
  type AppContextProviderProps,
  APP_CTX_LOADED_EVENT,
  DEFAULT_APP_CTX,
  EVENTS
} from "@/console/app/context/AppContextProvider"

export type AppContextLoaderProps = Omit<AppContextProviderProps, "value">

function AppContextLoader(props: AppContextLoaderProps) {
  const { addNotification } = useNotifications()

  const { data, refetch } = useAppContextQuery({
    notifyOnNetworkStatusChange: false,
    pollInterval: ONE_HOUR_IN_MS,
    variables: {
      geodataTokenInput: {
        referer: window.location.origin
      }
    },
    onCompleted(d) {
      IdentityManager.registerToken({
        ssl: true,
        server: config.geodataServerBaseUrl,
        token: d.getGeodataToken.Token
        // expires: getGeodataToken.Expires
      })

      IdentityManager.registerToken({
        ssl: true,
        server: config.layerServerBaseUrl,
        token: d.getLayerServerToken.Token,
        expires: d.getLayerServerToken.Expires
      })

      // TEMP WORKAROUND UNTIL THE OLD SERVER IS TAKEN DOWN:
      IdentityManager.registerToken({
        ssl: true,
        server: "https://layers2-next.placepoint.no/arcgis/rest/services",
        token: d.getLayerServerToken.Token,
        expires: d.getLayerServerToken.Expires
      })
      // TEMP WORKAROUND

      setUser(d.getCurrentUser)
      identify(d.getCurrentUser)

      // @note: could add context as event payload if needed
      EVENTS.dispatchEvent(new CustomEvent(APP_CTX_LOADED_EVENT))
    },
    onError(e) {
      const dismiss = addNotification({
        timeout: false,
        intent: "danger",
        title: "En feil oppstod med autentiseringen",
        iconSrc: "exclamation",
        content: (
          <AppContextErrorNotification
            error={e}
            onRetry={() => {
              refetch()
              dismiss()
            }}
            onDismiss={() => {
              dismiss()
            }}
          />
        )
      })
    }
  })

  const context = useMemo(() => {
    if(!data) return DEFAULT_APP_CTX
    return {
      currentUser: data.getCurrentUser,
      events: DEFAULT_APP_CTX.events,
      features: groupFeature2WithValueTypeByApiName(data.getFeatures2WithValueType),
      layerServerToken: data.getLayerServerToken?.Token
    }
  }, [
    data
  ])

  return (
    <AppContextProvider
      value={context}
      {...props}
    />
  )
}

export default AppContextLoader
