import { useSubscription } from "@apollo/client"
import { useNotifications } from "@/ui/Notification"
import subscription from "./notification.subscription.gql"
import type { NotificationSubscriptionVariables }
  from "./__types__/notification.subscription"

function useNotificationSubscription() {
  const { addNotification } = useNotifications()
  useSubscription<
    any, // NotificationSubscription,
    NotificationSubscriptionVariables
  >(subscription, {
    onData(options) {
      const props = options.data.data.onSystemNotification
      addNotification(props)
    }
  })
}

export default useNotificationSubscription
