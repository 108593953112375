import type { ComponentProps } from "react"
import cn from "@/ui/utils/utils.cn"

export type FrameHeaderProps = ComponentProps<"header">

function FrameHeader(props: FrameHeaderProps) {
  const { className, ...headerProps } = props
  return (
    <header
      className={cn("frame-header", className)}
      {...headerProps}
    />
  )
}

export default FrameHeader
