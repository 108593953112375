import { useEffect } from "react"
import { dragElement, querySelectorLazy } from "@/ui/utils/utils.dom"
// import { useFrameContext } from "@/ui/Frame/frame.context"

/**
 * Cannot be used with a backdrop enabled. Take no effect unless it finds
 * both .dialog2 and .dialog2-header elements in DOM
 */
function useDraggable(
  dialogId: string,
  isDraggable: boolean,
  hasBackdrop: boolean
) {
  // const { isFullscreen } = useFrameContext()

  useEffect(() => {
    if(!isDraggable) return
    if(hasBackdrop) {
      throw new Error("hasBackdrop=true cannot be used with isDraggable=true")
    }

    let deactivate: (resetPosition?: boolean) => void

    const dialogSelector = `[id="${dialogId}"].dialog2`
    const selectors = [ dialogSelector, `${dialogSelector} .dialog2-header` ]

    const interval = querySelectorLazy(selectors, (els) => {
      const [ target, activator ] = els
      const drag = dragElement({ target, activator })
      drag.activate()
      deactivate = drag.deactivate
    })

    return () => {
      clearInterval(interval)
      deactivate?.()
    }
  }, [
    dialogId,
    hasBackdrop,
    isDraggable
    // isFullscreen
  ])
}

export default useDraggable
