import type { TypePolicies } from "@apollo/client"
import { InMemoryCache, defaultDataIdFromObject } from "@apollo/client"
import { getObjectTags } from "@/console/object/tag/object.tag.typePolicies"
import { getLandregister }
  from "@/console/cadastre/landregister/cadastre.landregister.typePolicies"
import { getCadastreOwnerships }
  from "@/console/cadastre/ownership/cadastre.ownership.typePolicy"
import { getActivitiesByProjectByNextPrev }
  from "@/console/project/activity/timeline/project.activity.timeline.typePolicy"
import { createActivityTask, removeActivityTask }
  from "@/console/activity/task/activity.task.typePolicy"
import { createActivityNote, removeActivityNote }
  from "@/console/activity/note/activity.note.typePolicy"
import { createProjectObject }
  from "@/console/object/project/add/object.project.add.typePolicy"
import { getCompanySummary }
  from "@/console/company/summary/company.summary.typePolicy"
import { getPersonById }
  from "@/console/person/summary/person.summary.typePolicy"
import { getOccupancyById }
  from "@/console/occupancy/occupancy.typePolicy"
import { ReportPowerbi, getReportPowerbi }
  from "@/console/report/powerbi/report.powerbi.typePolicy"
import { FeatureValueType }
  from "@/console/feature/feature2WithValueType.typePolicy"

const typePolicies: TypePolicies = {
  FeatureValueType,
  ReportPowerbi,
  Query: {
    fields: {
      getObjectTags,
      getLandregister,
      getCadastreOwnerships,
      getActivitiesByProjectByNextPrev,
      getCompanySummary,
      getPersonById,
      getOccupancyById,
      getReportPowerbi
    }
  },
  Mutation: {
    fields: {
      createActivityTask,
      removeActivityTask,
      createActivityNote,
      removeActivityNote,
      createProjectObject
    }
  }
}

type DataObj = {
  Id?: string,
  Code?: string,
  __typename: string
}

const dataIdFromObject = (obj: DataObj) => {
  const id = obj.Id || obj.Code
  return id
    ? `${obj.__typename}:${id}`
    : defaultDataIdFromObject(obj)
}

const cache = new InMemoryCache({
  typePolicies,
  dataIdFromObject
})

export default cache
