import { InteractionRequiredAuthError } from "@azure/msal-browser"
import { BrowserTracing, init, setUser as setSentryUser }
  from "@sentry/react"
import type { CurrentUserFragment }
  from "@/console/currentUser/__types__/currentUser.fragment"
import config from "@/console/config"

const TRACE_SAMPLE_RATE = 0.2
const BLACKLIST = [
  "js.arcgis.com",
  "arcgis.com/sharing",
  "services.geodataonline.no",
  "segment"
]

function getBaseUrl(url: string) {
  return url.split("?")[0]
}

if(config.isInstrumentationEnabled) {
  init({
    dsn: config.sentryDsn,
    dist: "placepoint-console",
    release: config.version,
    environment: config.env,
    beforeSend(event, hint) {
      const e = hint.originalException
      if(!e) return event
      if(e instanceof InteractionRequiredAuthError) return null
      if(e instanceof Error) {
        if(/access denied/i.test(e.message)) return null
        if(/could not resolve endpoints/i.test(e.message)) return null
        if(/failed due to timeout/i.test(e.message)) return null
      }
      return event
    },
    beforeBreadcrumb(breadcrumb) {
      const { data } = breadcrumb
      if(data) {
        const { url, from, to } = data
        // filter layers / basemap from geodata
        if(url && BLACKLIST.some((u) => url.includes(u))) {
          return null
        }
        // filter duplicate urls
        if(from && to && getBaseUrl(from) === getBaseUrl(to)) {
          return null
        }
      }
      return breadcrumb
    },
    tracesSampler(ctx) {
      const { request } = ctx
      if(request?.url) {
        const { url } = request
        // used by azure app service for health checks
        if(url.includes("/__hs")) return 0
        if(url.includes("/robots933456.txt")) return 0
        if(url.includes("/robots.txt")) return 0
      }
      return TRACE_SAMPLE_RATE
    },
    tracePropagationTargets: [
      "localhost",
      /.*placepoint.*/g,
      /^\/auth-check/,
      /^\/rest/,
      /^\/api/,
      /^\/graphql/
    ],
    integrations: [
      new BrowserTracing({
        startTransactionOnLocationChange: false,
        shouldCreateSpanForRequest(url) {
          if(BLACKLIST.some((u) => url.includes(u))) {
            return false
          }

          // Azure health checks
          if(url.includes("/__hs")) return false
          if(url.includes("/robots933456.txt")) return false
          if(url.includes("/robots.txt")) return false

          // if(url.includes("/graphql")) return true
          if(url.includes("/rest")) return true
          if(url.includes("/api")) return true
          if(url.includes("/auth-check")) return true
          if(url.includes("placepoint.no")) return true

          return false
        }
      })
    ]
  })
}

export function setUser(currentUser: CurrentUserFragment) {
  if(!config.isInstrumentationEnabled) return
  setSentryUser({
    Id: currentUser.Id,
    AzureId: currentUser.AzureID,
    FirstName: currentUser.FirstName,
    LastName: currentUser.FirstName,
    Email: currentUser.Email,
    ActiveTenantId: currentUser.ActiveTenantId,
    CreatedAt: currentUser.CreatedAt
  })
}
