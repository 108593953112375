import { StrictMode } from "react"
import { createRoot } from "react-dom/client"
import "./auth/auth.instance"
import "./sentry"
import "./analytics"
import "@/ui/css/index.css"
import App from "./app/App"
import "@/ui/css/utilities.css"

const el = document.querySelector<HTMLDivElement>("#root")
if(el) {
  const root = createRoot(el)
  root.render(<StrictMode><App /></StrictMode>)
}
