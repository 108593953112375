import type { ComponentProps } from "react"
import cn from "@/ui/utils/utils.cn"
import "./current.user.tenant.css"
import CurrentUserTenantItem from "./CurrentUserTenantItem"
import type { CurrentUserFragment }
  from "@/console/currentUser/__types__/currentUser.fragment"

type BaseProps = Omit<ComponentProps<"div">, "onChange">

export type CurrentUserTenantPickerProps = BaseProps & {
  userTenants: CurrentUserFragment["UserTenants"],
  activeUserTenant: CurrentUserFragment["UserTenants"][0],
  onChange?(v: CurrentUserFragment["UserTenants"][0]): void,
  isMini?: boolean
}

function CurrentUserTenantPicker(
  props: CurrentUserTenantPickerProps
) {
  const {
    userTenants,
    activeUserTenant,
    isMini,
    onChange,
    className,
    ...divProps
  } = props

  return (
    <div
      className={cn("current-user-menu-tenant-picker", className)}
      {...divProps}
    >
      {userTenants.map((ut) => {
        const isActive = ut.TenantId === activeUserTenant.TenantId
        return (
          <CurrentUserTenantItem
            key={ut.TenantId}
            isMini={isMini}
            userTenant={ut}
            className={cn({
              "is-active": isActive
            })}
            aria-label={isActive
              ? "Dette medlemskapet er aktivt"
              : "Sett som aktiv"
            }
            iconSrc={isActive
              ? "check"
              : null
            }
            onClick={() => {
              onChange(ut)
            }}
          />
        )
      })}
    </div>
  )
}

export default CurrentUserTenantPicker
