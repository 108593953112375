import { useCallback, useEffect, useRef } from "react"
import { safeJSONParse } from "@/common/utils/utils.misc"
import type { Theme }
  from "@/console/theme/theme.types"
import {
  THEMES,
  THEME_NAMES,
  LOCAL_STORAGE_KEY,
  DEFAULT_THEMES_NAMES
} from "@/console/theme/theme.constants"
import {
  useThemeStorage,
  getSystemTheme
} from "@/console/theme/theme.var"
import {
  assertIsTheme
} from "@/console/theme/theme.utils"

export type ThemeSwitcherProps = {
  storageKey?: string,
  defaultTheme?: Theme,
  onMount?(theme: Theme): void,
  onChange?(theme: Theme): void,
  children: (props: {
    toggleTheme: () => void,
    themeName: string
  }) => JSX.Element
}

/*
 * A Themswitcher component that listens to system theme changes and stores the theme in localstorage
 * By default it will yse the system preferences
 * If the user has set a theme, it will use that instead
 * If a user changes the theme in one tab, other tabs will be updated aswell
 * */
function ThemeSwitcher(props: ThemeSwitcherProps) {
  const {
    storageKey = LOCAL_STORAGE_KEY,
    defaultTheme,
    onMount,
    onChange,
    children
  } = props
  const isMounted = useRef(false)

  const { theme, setTheme } = useThemeStorage()

  useEffect(() => {
    if(!theme || isMounted.current) return
    onMount?.(theme)
    isMounted.current = true
  }, [ theme, onMount ])

  // if no theme is given we use the system theme
  const applyTheme = useCallback((theme: Theme) => {
    let resolved = theme
    // if no theme and no system theme, do nothing
    if(!resolved) {
      resolved = getSystemTheme()
    }

    const d = document.documentElement

    // remove all other themes
    d.classList.remove(...DEFAULT_THEMES_NAMES)
    // add new theme
    d.classList.add(resolved.name)

    localStorage.setItem(storageKey, JSON.stringify(resolved))
  }, [ storageKey ])

  // localStorage event handling
  useEffect(() => {
    const handleStorage = (e: StorageEvent) => {
      if(e.key !== storageKey) {
        return
      }

      const parsedValue = safeJSONParse(e.newValue)
      const theme = assertIsTheme(parsedValue) ? parsedValue : defaultTheme
      setTheme(theme)
    }

    window.addEventListener("storage", handleStorage)
    return () => window.removeEventListener("storage", handleStorage)
  }, [ setTheme, defaultTheme, storageKey ])

  // make sure the first theme is applied
  useEffect(() => {
    applyTheme(theme)
  }, [ theme, applyTheme ])

  const toggleTheme = () => {
    const newTheme = theme.name === THEME_NAMES.light ? THEMES.dark : THEMES.light
    setTheme(newTheme)
    applyTheme(newTheme)

    // call onChange callback
    onChange?.(newTheme)
  }

  return children({ toggleTheme, themeName: theme.name })
}

export default ThemeSwitcher
