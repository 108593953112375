import { useMemo } from "react"
import cn from "@/ui/utils/utils.cn"
import Popover from "@/ui/Popover"
import Avatar, { type AvatarProps } from "@/console/components/Avatar"
import ConditionalWrapper from "@/console/components/ConditionalWrapper"
import { getRandomColorHueBySeed } from "@/console/utils/color.utils"
import { getUserFullName, getUserInitials } from "@/console/user/user.utils"
import type { UserFragment } from "@/console/user/__types__/user.fragment"

type User = Omit<UserFragment, "__typename"> & {
  Email?: string,
  AzureID?: string
}

type BaseProps = Omit<AvatarProps, "children">
export type UserAvatarProps = BaseProps & {
  user: User | string,
  isTooltipDisabled?: boolean
}

function UserAvatar(props: UserAvatarProps) {
  const {
    user,
    style,
    isTooltipDisabled,
    className,
    ...avatarProps
  } = props

  const isString = typeof user === "string"

  const avatarStyle = useMemo(() => {
    if(!user) return style
    const seed = isString
      ? user
      : String(user.Email || user.AzureID || user.Id)

    const hue = getRandomColorHueBySeed(seed)
    return {
      backgroundColor: `hsl(${hue}, 60%, 35%)`,
      border: `1px solid hsl(${hue}, 60%, 45%)`,
      ...style
    }
  }, [
    isString,
    user,
    style
  ])

  const avatar = (
    <Avatar
      className={cn("user-avatar", className)}
      style={avatarStyle}
      {...avatarProps}
    >
      {isString
        ? user
        : getUserInitials(user)
      }
    </Avatar>
  )

  if(isString) {
    return avatar
  }

  return (
    <ConditionalWrapper
      condition={!isTooltipDisabled}
      renderWrapper={(children) => (
        <Popover content={getUserFullName(user)}>
          {children}
        </Popover>
      )}
    >
      {avatar}
    </ConditionalWrapper>
  )
}

export default UserAvatar
