import cn from "@/ui/utils/utils.cn"
import Frame, { type FrameBodyProps } from "@/ui/Frame"

export type Dialog2BodyProps = FrameBodyProps

function Dialog2Body(props: Dialog2BodyProps) {
  const { className, ...frameBodyProps } = props
  return (
    <Frame.Body
      className={cn("dialog2-body", className)}
      {...frameBodyProps}
    />
  )
}

export default Dialog2Body
