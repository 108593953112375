import cn from "@/ui/utils/utils.cn"
import { TooltipButton, type TooltipButtonProps } from "@/ui/Button"
import { useFrameContext } from "@/ui/Frame/frame.context"

export type FrameCloseButtonProps = TooltipButtonProps

function FrameCloseButton(props: FrameCloseButtonProps) {
  const { className, onClick, ...tooltipButtonProps } = props
  const { onHide } = useFrameContext()
  return (
    <TooltipButton
      containerClassName={cn("frame-close-button", className)}
      popoverClassName="z-20"
      iconSrc="cross"
      content="Lukk"
      onClick={(e) => {
        if(onClick) onClick(e)
        onHide()
      }}
      {...tooltipButtonProps}
    />
  )
}

export default FrameCloseButton
