import type { ServerError, Operation } from "@apollo/client"
import { createHttpLink, from, split, concat } from "@apollo/client"
import { setContext } from "@apollo/client/link/context"
import { RetryLink } from "@apollo/client/link/retry"
import { RestLink } from "apollo-link-rest"
import { getMainDefinition } from "@apollo/client/utilities"
import { traceLink } from "@/console/apollo/apollo.trace"
import { getIsAuthServerError } from "@/console/apollo/apollo.utils"
import { idToken } from "@/console/auth/auth.instance"
import { dedupeLink } from "@/console/apollo/apollo.dedupe"
import { errorLink } from "@/console/apollo/apollo.error"
import wsLink from "@/console/apollo/apollo.ws"
import config from "@/console/config"

const contextLink = setContext((op, ctx) => {
  if(!idToken) return ctx
  if(!ctx.headers) ctx.headers = {}
  ctx.headers.authorization = `Bearer ${idToken}`
  if(op.operationName) {
    ctx.headers.op = op.operationName
  }
  return ctx
})

function getDefinitionOperation(op: Operation) {
  const definition = getMainDefinition(op.query)
  if(definition.kind === "OperationDefinition") {
    return definition.operation
  }
  return null
}

export function shouldUseWebSocket(op: Operation) {
  return getDefinitionOperation(op) === "subscription"
}

const retryLink = new RetryLink({
  attempts: {
    max: 1,
    retryIf(e: ServerError, _op) {
      return e && getIsAuthServerError(e) === false
    }
  }
})

export const httpLinkGraphql = createHttpLink({
  uri: config.graphqlUrl
})

function shouldUseDedupeLink(op: Operation) {
  const context = op.getContext()
  return Boolean(context.isDedupe)
}

const httpLinkGraphqlDedupeLink = split(
  shouldUseDedupeLink,
  concat(dedupeLink, httpLinkGraphql),
  httpLinkGraphql
)

const httpLinkRest = new RestLink({
  uri: config.apiUrl + "/rest/"
})

function shouldUseRestLink(op: Operation) {
  const context = op.getContext()
  return Boolean(context.isRest)
}

const httpLink = split(
  shouldUseRestLink,
  httpLinkRest,
  httpLinkGraphqlDedupeLink
)

export const httpTraceLink = split(
  () => config.isInstrumentationEnabled,
  concat(traceLink, httpLink),
  httpLink
)

const httpLinks = concat(
  retryLink,
  httpTraceLink
)

const httpOrWsLink = split(
  shouldUseWebSocket,
  wsLink,
  httpLinks
)

const links = from([
  errorLink,
  contextLink,
  httpOrWsLink
])

export default links
