import { ApolloLink, from } from "@apollo/client"
import { getCurrentHub } from "@sentry/react"
import type { Span } from "@sentry/types"
import config from "@/console/config"

type Context = {
  span?: Span
}

const start = new ApolloLink((operation, forward) => {
  const { getContext, operationName, variables } = operation
  const context: Context = getContext()

  const hub = getCurrentHub()
  const span = hub.startTransaction({
    op: "graphql.request",
    name: operationName,
    description: operationName
  })

  span.setTag("environment.region", config.region)
  span.setTag("environment.slot", config.slot)
  span.setTag("graphql.operationName", operationName)
  if(variables) span.setData("graphql.variables", variables)

  const scope = hub.getScope()
  scope.setSpan(span)

  context.span = span
  operation.setContext(context)
  return forward(operation)
})

const end = new ApolloLink((operation, forward) => {
  return forward(operation).map((data) => {
    const { span }: Context = operation.getContext()
    if(span) span.finish()
    return data
  })
})

export const traceLink = from([ start, end ])
