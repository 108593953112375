import { withProfiler, ErrorBoundary } from "@sentry/react"
import { NotificationProvider } from "@/ui/Notification"
import ErrorFallback from "@/console/error/ErrorFallback"
import Router from "@/console/router/Router"

function App() {
  return (
    <ErrorBoundary
      showDialog
      fallback={ErrorFallback}
    >
      <NotificationProvider>
        <Router />
      </NotificationProvider>
    </ErrorBoundary>
  )
}

export default withProfiler(App)
