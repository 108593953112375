import type { ComponentProps } from "react"
import cn from "@/ui/utils/utils.cn"
import Icon from "@/ui/Icon"
import type { IconProps } from "@/ui/Icon"
import Input from "@/ui/Form/Input"
import type { InputProps } from "@/ui/Form/Input"
import type { ButtonProps } from "@/ui/Button"
import useSidebarContext from "./sidebar.context"

export type SidebarSearchProps = ComponentProps<"div"> & {
  isLoading?: boolean,
  iconSrc?: IconProps["src"],
  iconClassName?: IconProps["className"],
  iconSize?: IconProps["size"],
  iconStyle?: IconProps["style"],
  inputClassName?: InputProps["className"],
  inputPlaceholder?: InputProps["placeholder"],
  inputStyle?: InputProps["style"],
  value?: InputProps["value"],
  defaultValue?: InputProps["defaultValue"],
  onChange?: InputProps["onChange"],
  resetButtonClassName?: ButtonProps["className"],
  resetButtonStyle?: ButtonProps["style"],
  onReset?: ButtonProps["onClick"]
}

function SidebarSearch(props: SidebarSearchProps) {
  const { isMini } = useSidebarContext()
  const {
    className,
    isLoading,
    iconSrc = "search",
    iconClassName,
    iconStyle,
    iconSize = 14,
    inputClassName,
    inputPlaceholder = "Søk..",
    inputStyle,
    value,
    defaultValue,
    onChange,
    resetButtonClassName,
    resetButtonStyle,
    onReset,
    ...divProps
  } = props

  return (
    <div
      className={cn("sidebar-search field-input", className, {
        "is-mini": isMini
      })}
      {...divProps}
    >
      <Icon
        className={cn("sidebar-search-icn", iconClassName)}
        src={iconSrc}
        loading={isLoading}
        style={iconStyle}
        size={iconSize}
      />

      <Input
        className={cn("sidebar-search-input", inputClassName)}
        style={inputStyle}
        placeholder={inputPlaceholder}
        value={value}
        defaultValue={defaultValue}
        onChange={onChange}
      />

      {isMini
        ? null
        : <Input.ResetButton
            className={resetButtonClassName}
            style={resetButtonStyle}
            onClick={onReset}
          />
      }
    </div>
  )
}

export default SidebarSearch
