import { useEffect, useState } from "react"
import type { NavigateFunction } from "react-router-dom"
import { NavigationClient } from "@azure/msal-browser"
import type { NavigationOptions, PublicClientApplication }
  from "@azure/msal-browser"
import { useNavigate } from "@/console/router/router.utils"

/**
 * This is an example for overriding the default function MSAL uses
 * to navigate to other urls in your webpage
 */
export class AuthNavigationClient extends NavigationClient {
  navigate: NavigateFunction

  constructor(navigate) {
    super()
    this.navigate = navigate
  }

  /**
   * Navigates to other pages within the same web application
   * You can use the useNavigate hook provided by react-router-dom
   * to take advantage of client-side routing
   * @param url
   * @param options
   */
  // eslint-disable-next-line require-await
  override async navigateInternal(url: string, options: NavigationOptions) {
    const relativePath = url.replace(window.location.origin, "")

    if(options.noHistory) {
      this.navigate(relativePath, { replace: true })
    } else {
      this.navigate(relativePath)
    }

    return false
  }
}

/**
 * This component is optional. This is how you configure MSAL to take
 * advantage of the router's navigate functions when MSAL redirects
 * between pages in your app
 */
type AuthNavigationProps = {
  instance: PublicClientApplication,
  children: any
}

function AuthNavigation(props: AuthNavigationProps) {
  const { instance, children } = props
  const navigate = useNavigate()

  const navigationClient = new AuthNavigationClient(navigate)
  instance.setNavigationClient(navigationClient)

  // react-router-dom v6 doesn't allow navigation on the first render - delay
  // rendering of MsalProvider to get around this limitation
  const [ firstRender, setFirstRender ] = useState(true)
  useEffect(() => {
    setFirstRender(false)
  }, [])

  if(firstRender) {
    return null
  }

  return children
}

export default AuthNavigation
