import { track, type TrackProperties } from "@/console/analytics"

export function trackAppSidebarNavigated(
  to: string,
  props?: Partial<TrackProperties>
) {
  track("AppSidebar_Navigated", {
    module: "AppSidebar",
    action: "Navigated",
    to,
    sprintNo: 0,
    issueId: 0,
    ...props
  })
}
