import { useCallback } from "react"
import { makeVar, useReactiveVar } from "@apollo/client"
import useLocalStorage, { getValue } from "@/ui/utils/utils.localStorage"
import type { Theme } from "@/console/theme/theme.types"
import {
  THEMES,
  LOCAL_STORAGE_KEY
} from "@/console/theme/theme.constants"

const MEDIA = "(prefers-color-scheme: dark)"
const DEFAULT_STYLE = THEMES.dark

// gets the system theme using media query
export const getSystemTheme = (e?: MediaQueryList | MediaQueryListEvent) => {
  if(!e) e = window.matchMedia(MEDIA)
  const isDark = e.matches
  const systemTheme = isDark ? THEMES.dark : THEMES.light
  return systemTheme
}

const getInitialTheme = () => {
  return getValue(LOCAL_STORAGE_KEY, getSystemTheme())
}

// Gets the theme from local storage
// if no theme is set, e.g first time user opens placepoint,
// the system theme is used instead
export const themeVar = makeVar<Theme>(getInitialTheme())

// @note: do NOT export. should be used with the useThemeStorage
function setThemeVar(
  theme: Theme
) {
  return themeVar(theme)
}

// can be exported as it will not mutate local storage
export function useThemeVar() {
  return useReactiveVar(themeVar)
}

// can be exported as it will not mutate local storage
export function getThemeVar() {
  return themeVar()
}

export function useThemeStorage() {
  const theme = useThemeVar()

  const {
    removeItem: removeItemStorage,
    setItem: setItemStorage
  } = useLocalStorage<Theme>(LOCAL_STORAGE_KEY,
    getThemeVar()
  )

  const removeTheme = useCallback(() => {
    // remove from local storage
    removeItemStorage()
    // remove from reactive var
    setThemeVar(DEFAULT_STYLE)
  }, [ removeItemStorage ])

  const setTheme = useCallback((value: Theme) => {
    // remove from local storage
    setItemStorage(value)
    // remove from reactive var
    setThemeVar(value)
  }, [ setItemStorage ])

  return {
    removeTheme,
    setTheme,
    theme
  }
}
