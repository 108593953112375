import Button from "@/ui/Button"
import { getAssetUrl } from "@/ui/utils/utils.assets"

const IMG_SRC = getAssetUrl("images/icons/update-required.svg")

function ErrorOutdated() {
  return (
    <div className="error-outdated h-screen max-w-lg mx-auto flex justify-center flex-col text-center pb-24">
      <img
        alt="Ny version"
        src={IMG_SRC}
        className="w-24 mx-auto"
      />

      <div className="mt-12 mx-auto text-xl">
        Ny versjon tilgjengelig!
      </div>

      <p className="my-12">
        Det ser ut som en ny komponent i appen har blitt oppdatert på serveren, men har ikke blitt lastet ned enda. Vi ber deg vennligst oppdatere appen, ved å trykke på knappen under.
      </p>

      <Button
        intent="primary"
        className="error-outdated__reload mt-4 mx-auto"
        onClick={() => {
          window.location.reload()
        }}
      >
        Oppdater
      </Button>
    </div>
  )
}

export default ErrorOutdated
