import { useEffect } from "react"
import { useNavigate } from "@/console/router/router.utils"

function Redirect() {
  const navigate = useNavigate()
  useEffect(() => {
    navigate("/map", { replace: false })
  }, [
    navigate
  ])
  return null
}

export default Redirect
