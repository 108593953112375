import type { Obj } from "@/common/types"

export function parseParams<T = Obj>(s: string): T {
  if(!s || s === "") return null
  const p = new URLSearchParams(s.charAt(0) === "?"
    ? s.slice(1)
    : s
  )
  return Object.fromEntries(p) as T
}

export function stringifyParams(o: Record<string, any>): string {
  return Object.entries(o)
    .filter(([ _key, value ]) => {
      if(value === false || value === 0) return true
      if(value && value.length === 0) return false
      return !!value
    })
    .map(([ key, value ]) => {
      const urlKey = encodeURIComponent(key)
      const urlValue = encodeURIComponent(value)
      return `${urlKey}=${urlValue}`
    })
    .join("&")
}

export function parsePath<T = Obj>(path: string, tmp?: string) {
  const vars = {} as T
  if(!tmp) return vars

  const tmpSplit = tmp.split("/")
  const pathSplit = path.split("/")

  let index = 0
  for(const p of tmpSplit) {
    if(p.charAt(0) === ":" && pathSplit[index]) {
      vars[p.slice(1)] = pathSplit[index]
    }
    index++
  }

  return vars
}

interface HttpError extends Error {
  statusText: string
  statusCode: number
}

export function httpError(res: Response): HttpError {
  const error: HttpError = {
    ...new Error(res.statusText),
    statusText: res.statusText,
    statusCode: res.status
  }
  throw error
}
