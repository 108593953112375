import Sidebar, { type SidebarItemProps } from "@/ui/Sidebar"
import { useSearchParamOpener } from "@/console/components/component.utils"
import { ANALYZE_PARAM_KEY } from "@/console/analyze/analyze.constants"

export type AnalyzePanelSidebarItemProps = SidebarItemProps

function AnalyzePanelSidebarItem(
  props: AnalyzePanelSidebarItemProps
) {
  const { onClick, ...sidebarItemProps } = props
  const nav = useSearchParamOpener(ANALYZE_PARAM_KEY)
  return (
    <Sidebar.Item
      isActive={nav.isActive}
      onClick={(e) => {
        onClick?.(e)
        nav.isActive
          ? nav.close()
          : nav.open("0")
      }}
      {...sidebarItemProps}
    />
  )
}

export default AnalyzePanelSidebarItem
