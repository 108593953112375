import cn from "@/ui/utils/utils.cn"
import { TooltipButton, type TooltipButtonProps } from "@/ui/Button"
import { useFrameContext } from "@/ui/Frame/frame.context"

export type FrameFullscreenButtonProps = TooltipButtonProps

function FrameFullscreenButton(props: FrameFullscreenButtonProps) {
  const { className, onClick, ...tooltipButtonProps } = props
  const { onFullscreen, isFullscreen } = useFrameContext()
  return (
    <TooltipButton
      containerClassName={cn("frame-fullscreen-button", className)}
      popoverClassName="z-20"
      content={isFullscreen
        ? "Gjør mindre"
        : "Gjør større"
      }
      iconSrc={isFullscreen
        ? "compress"
        : "expand"
      }
      onClick={(e) => {
        if(onClick) onClick(e)
        onFullscreen(!isFullscreen)
      }}
      {...tooltipButtonProps}
    />
  )
}

export default FrameFullscreenButton
