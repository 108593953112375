import { track } from "@/console/analytics"
import type { ThemeName } from "@/console/theme/theme.types"

export function trackThemeFromUser(themeType: ThemeName) {
  track("Theme_Updated", {
    module: "Theme",
    action: "UpdatedByUser",
    themeType,
    issueId: 4226,
    sprintNo: 22
  })
}

export function trackThemeLogged(themeType: ThemeName) {
  track("Theme_Logged", {
    module: "Theme",
    action: "Logged",
    themeType,
    issueId: 4226,
    sprintNo: 22
  })
}
