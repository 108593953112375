import type { PackageCodes } from "./package.constants"
import { PACKAGE_CODES } from "./package.constants"
import cn from "@/ui/utils/utils.cn"

export type PackageInfoProps = {
  packageCode?: PackageCodes,
  packageIcon?: string,
  packageName?: string,
  isCustom?: boolean,
  iconClassName?: string
}

function PackageInfo(props: PackageInfoProps) {
  const {
    packageCode = PACKAGE_CODES.starter,
    packageIcon,
    isCustom,
    packageName,
    iconClassName
  } = props

  return (
    <>
      {packageIcon
        ? <img
            className={iconClassName}
            src={packageIcon}
            alt={`${packageName} package icon`}
          />
        : null
      }

      <div
        className={cn("tier-badge-text", {
          "package-color-starter-text": packageCode === PACKAGE_CODES.starter,
          "package-color-standard-text": packageCode === PACKAGE_CODES.standard,
          "package-color-premium-text": packageCode === PACKAGE_CODES.premium,
          "package-color-custom-text": isCustom
        })}
      >
        {packageName?.toUpperCase()}
      </div>
    </>
  )
}

export default PackageInfo
