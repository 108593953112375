import { useLocation } from "react-router-dom"
import Sidebar from "@/ui/Sidebar"
import config from "@/console/config"
import { useNavigate } from "@/console/router/router.utils"
import CurrentUserMenu from "@/console/currentUser/CurrentUserMenu"
import { trackAppSidebarNavigated } from "@/console/app/app.tracking"
import AppSidebarItemGlobalSearch
  from "@/console/app/sidebar/AppSidebarItemGlobalSearch"
import AnalyzePanelSidebarItem from "@/console/analyze/AnalyzePanelSidebarItem"
import AppSidebarItemsProject from "@/console/app/sidebar/items/AppSidebarItemsProject"
import AppSidebarItemModels from "@/console/app/sidebar/AppSidebarItemModels"
import AppSidebarItemTheme from "@/console/app/sidebar/AppSidebarItemTheme"
import AppSidebarItemsLayer from "@/console/app/sidebar/items/AppSidebarItemsLayer"

function AppSidebar() {
  const navigate = useNavigate()
  const location = useLocation()

  function getIsActive(path: string) {
    return location.pathname.includes(path)
  }

  return (
    <Sidebar
      className="app-sidebar flex flex-col min-w-[190px] max-w-[190px] h-full bg-bg0 dark:bg-bg00"
      role="navigation"
      aria-label="Hovedmeny"
    >
      <Sidebar.Branding
        className="app-sidebar-logo px-6 pb-2"
      />

      <hr className="h-px m-2 bg-bg000 dark:bg-bg2" />

      <div className="px-3">
        <AppSidebarItemGlobalSearch
          className="app-sidebar__to-global-search pr-2"
          iconSrc="search"
          onClick={() => {
            trackAppSidebarNavigated("GlobalSearch")
          }}
        />

        <AppSidebarItemsLayer
          className="app-sidebar__to-layers"
          iconSrc="layers"
          onClick={() => {
            trackAppSidebarNavigated("Layers", {
              latestIssueId: 4237,
              latestSprintNo: 30
            })
          }}
        >
          Kartlag
        </AppSidebarItemsLayer>

        <Sidebar.Item
          className="app-sidebar__to-tags"
          iconSrc="bookmark"
          isActive={getIsActive("tags")}
          onClick={() => {
            navigate("/map/tags")
            trackAppSidebarNavigated("Tags")
          }}
        >
          Tags
        </Sidebar.Item>

        <Sidebar.Item
          className="app-sidebar__to-reports"
          iconSrc="stats"
          isActive={getIsActive("reports")}
          onClick={() => {
            navigate("/map/reports")
            trackAppSidebarNavigated("Reports")
          }}
        >
          Rapporter
        </Sidebar.Item>

        <AppSidebarItemModels
          id="app-sidebar_sketch-3d-models"
          iconSrc="cube"
          isActive={getIsActive("models")}
          onClick={() => {
            navigate("/map/models")
            trackAppSidebarNavigated("Models")
          }}
        />

        <AnalyzePanelSidebarItem
          className="app-sidebar__to-analyze"
          iconSrc="bolt"
          onClick={() => {
            trackAppSidebarNavigated("Analyze", {
              issueId: 3503,
              sprintNo: 14
            })
          }}
        >
          Analyser
        </AnalyzePanelSidebarItem>

        <hr className="h-px -mx-1 my-2 bg-bg000 dark:bg-bg2" />

        <AppSidebarItemsProject />
      </div>

      <div className="mt-auto px-3">
        <AppSidebarItemTheme
          className="app-sidebar__to-theme pr-2"
        />

        <Sidebar.Item
          className="app-sidebar__to-settings"
          iconSrc="settings"
          isActive={getIsActive("user/settings")}
          onClick={() => {
            navigate("/map/user/settings")
            trackAppSidebarNavigated("Settings")
          }}
        >
          Innstillinger
        </Sidebar.Item>

        <Sidebar.Item
          className="app-sidebar__to-support"
          iconSrc="interrogation"
          onClick={() => {
            window.open(config.docsUrl)
            trackAppSidebarNavigated("Docs")
          }}
        >
          Hjelp & støtte
        </Sidebar.Item>
      </div>

      <hr className="h-px m-2 bg-bg000 dark:bg-bg2" />

      <CurrentUserMenu className="pt-0 p-3" />
    </Sidebar>
  )
}

export default AppSidebar
