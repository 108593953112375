import type { Theme } from "@/console/theme/theme.types"
import ThemeSwitcher, { type ThemeSwitcherProps } from "@/console/theme/ThemeSwitcher"
import { trackThemeFromUser, trackThemeLogged }
  from "@/console/theme/theme.tracking"

type ThemeSwitcherWithTrackingProps = Omit<ThemeSwitcherProps, "onMount" | "onChange">

function ThemeSwitcherWithTracking(
  props: ThemeSwitcherWithTrackingProps
) {
  return (
    <ThemeSwitcher
      onMount={(theme: Theme) => {
        trackThemeLogged(theme.name)
      }}
      onChange={(theme: Theme) => {
        trackThemeFromUser(theme.name)
      }}
      {...props}
    />
  )
}

export default ThemeSwitcherWithTracking
