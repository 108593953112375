import cn from "@/ui/utils/utils.cn"
import Frame, { type FrameHeaderProps } from "@/ui/Frame"

export type Dialog2HeaderProps = FrameHeaderProps

function Dialog2Header(props: Dialog2HeaderProps) {
  const { className, ...frameHeaderProps } = props
  return (
    <Frame.Header
      className={cn("dialog2-header", className)}
      {...frameHeaderProps}
    />
  )
}

export default Dialog2Header
