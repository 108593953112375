import type { ThemeName, Theme } from "@/console/theme/theme.types"

export const LOCAL_STORAGE_KEY = "theme"

export const DEFAULT_THEMES_NAMES: ThemeName[] = [ "light", "dark" ]

export const THEME_NAMES: Record<ThemeName, ThemeName> = {
  light: "light",
  dark: "dark"
}

export const THEMES: Record<ThemeName, Theme> = {
  light: {
    name: THEME_NAMES.light,
    energyLabelSymbolColor: "#CDD2D5",
    chartTicksColor: "#212121",
    chartTooltipColor: "#000",
    chartLegendColor: "#212121"
  },
  dark: {
    name: THEME_NAMES.dark,
    energyLabelSymbolColor: "#2a2f32",
    chartTicksColor: "#ddd",
    chartTooltipColor: "#000",
    chartLegendColor: "#ddd"
  }
}
