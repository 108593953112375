import Button, { type ButtonProps } from "@/ui/Button"

type AppContextErrorNotificationProps = {
  error: Error,
  onRetry?: ButtonProps["onClick"],
  onDismiss?: ButtonProps["onClick"]
}

function AppContextErrorNotification(
  props: AppContextErrorNotificationProps
) {
  const { error, onRetry, onDismiss } = props
  return (
    <div>
      Kunne dessverre ikke hente inn brukeren din. Du kan fortsette i begrenset modus eller forsøke å logge inn på nytt. Vi beklager dette.

      <div className="mt-2">
        Vi mottok feilmelding: <strong>{error.message}</strong>
      </div>

      <div className="mt-3">
        <Button
          small
          className="app-context-error__dismiss mr-1"
          onClick={onDismiss}
        >
          Fortsett..
        </Button>

        <Button
          small
          className="app-context-error__retry"
          intent="primary"
          onClick={onRetry}
        >
          Prøv igjen..
        </Button>
      </div>
    </div>
  )
}

export default AppContextErrorNotification
