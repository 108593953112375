import Sidebar, { type SidebarItemProps } from "@/ui/Sidebar"
import ThemeSwitcherWithTracking from "@/console/theme/ThemeSwitcherWithTracking"
import { THEME_NAMES } from "@/console/theme/theme.constants"

export type AppSidebarItemThemeProps = SidebarItemProps

function AppSidebarItemTheme(props: AppSidebarItemThemeProps) {
  const { onClick, ...sidebarItemProps } = props

  return (
    <ThemeSwitcherWithTracking>
      {({ toggleTheme, themeName }) => (
        <Sidebar.Item
          onClick={(e) => {
            onClick?.(e)
            toggleTheme()
          }}
          iconSrc={themeName === THEME_NAMES.light
            ? "sun"
            : "moon"
          }
          {...sidebarItemProps}
        >
          {themeName === THEME_NAMES.light
            ? "Lyst tema"
            : "Mørkt tema"
          }
        </Sidebar.Item>
      )}
    </ThemeSwitcherWithTracking>
  )
}

export default AppSidebarItemTheme
