import Sidebar, { type SidebarItemProps } from "@/ui/Sidebar"
import { useMapLayerPanel } from "@/console/map/layer/map.layer.utils"

function AppSidebarItemsLayer(props: SidebarItemProps) {
  const { onClick, ...sidebarItemProps } = props
  const panel = useMapLayerPanel()

  return (
    <Sidebar.Item
      isActive={panel.isActive}
      onClick={(e) => {
        panel.open()
        onClick?.(e)
      }}
      {...sidebarItemProps}
    />
  )
}

export default AppSidebarItemsLayer
