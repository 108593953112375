import type { ComponentProps } from "react"
import cn from "@/ui/utils/utils.cn"
import PlacepointLogo from "@/ui/PlacepointLogo"
import useSidebarContext from "./sidebar.context"

export type SidebarBrandingProps = ComponentProps<"div">

function SidebarBranding(props: SidebarBrandingProps) {
  const { isMini } = useSidebarContext()
  const { className, children, ...divProps } = props
  return (
    <div
      className={cn("sidebar-branding", className, {
        "is-mini": isMini
      })}
      {...divProps}
    >
      <PlacepointLogo
        className={cn({
          "mx-auto": isMini
        })}
      />

      {isMini
        ? null
        : <div className="sidebar-branding-text">
            {children || "Placepoint"}
          </div>
      }
    </div>
  )
}

export default SidebarBranding
