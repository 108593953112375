import cn from "@/ui/utils/utils.cn"
import Button from "./Button"
import type { ButtonProps } from "./Button"
import Popover from "@/ui/Popover"
import type { PopoverProps } from "@/ui/Popover"

type BaseProps = Omit<ButtonProps, "label" | "content">
export type TooltipButtonProps = BaseProps & {
  label?: string,
  content?: PopoverProps["content"],
  placement?: PopoverProps["placement"],
  arrowClassName?: PopoverProps["arrowClassName"],
  containerClassName?: string,
  contentClassName?: PopoverProps["popoverClassName"],
  popoverClassName?: PopoverProps["className"],
  popoverStyle?: PopoverProps["style"],
  isPortal?: PopoverProps["isPortal"],
  popoverShown?: PopoverProps["shown"],
  popoverOnClose?: PopoverProps["onClose"],
  popoverOnMouseEnter?: PopoverProps["onMouseEnter"],
  popoverOnMouseLeave?: PopoverProps["onMouseLeave"],
  popoverOnMouseDown?: PopoverProps["onMouseDown"],
  popoverOnMouseUp?: PopoverProps["onMouseUp"],
  popoverAutoCloseWhitelistIds?: PopoverProps["autoCloseWhitelistIds"],
  popoverId?: PopoverProps["id"]
}

function TooltipButton(props: TooltipButtonProps) {
  const {
    label,
    content,
    placement = "top",
    arrowClassName,
    containerClassName,
    contentClassName,
    popoverClassName,
    popoverStyle,
    isPortal,
    popoverShown,
    popoverOnClose,
    popoverOnMouseEnter,
    popoverOnMouseLeave,
    popoverOnMouseDown,
    popoverOnMouseUp,
    popoverAutoCloseWhitelistIds,
    popoverId,
    ...buttonProps
  } = props
  return (
    <Popover
      id={popoverId}
      arrowClassName={arrowClassName}
      className={containerClassName}
      contentClassName={contentClassName}
      popoverClassName={cn("tooltip-button__popover", popoverClassName)}
      style={popoverStyle}
      content={content || label}
      placement={placement}
      isPortal={isPortal}
      shown={popoverShown}
      onClose={popoverOnClose}
      onMouseEnter={popoverOnMouseEnter}
      onMouseLeave={popoverOnMouseLeave}
      onMouseDown={popoverOnMouseDown}
      onMouseUp={popoverOnMouseUp}
      autoCloseWhitelistIds={popoverAutoCloseWhitelistIds}
      isShownIndicated={false}
    >
      <Button
        aria-label={label}
        {...buttonProps}
      />
    </Popover>
  )
}

export default TooltipButton
