import { Outlet } from "react-router-dom"
import ApolloClientProvider from "@/console/apollo/ApolloClientProvider"
import AppContextLoader from "@/console/app/context/AppContextLoader"
import AppSidebar from "@/console/app/sidebar/AppSidebar"
import AppReadyIndicator from "@/console/app/AppReadyIndicator"
import HubspotChatSuspender from "@/console/hubspot/HubspotChatSuspender"
import NotificationSubscriber
  from "@/console/notification/NotificationSubscriber"

function AppOutlet() {
  return (
    <ApolloClientProvider>
      <AppContextLoader>
        <AppSidebar />
        <Outlet />
        <HubspotChatSuspender />
        <AppReadyIndicator />
      </AppContextLoader>
      <NotificationSubscriber />
    </ApolloClientProvider>
  )
}

export default AppOutlet
